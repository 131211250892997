import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import className from 'classnames'
import dynamic from 'next/dynamic'
import moment from 'moment'
import Link from 'next/link'

import {t} from '@lib/helpers'

const ImageLoader = dynamic(() => import('@components/ImageLoader'))

const BlogItem = ({data, isLeader}) => {
  return (
    <Link href='/blogs/[id]' as={`/blogs/${get(data, 'slug')}`}>
      <a
        className={className('BlogItem', {
          'BlogItem--leader': isLeader,
        })}
      >
        <div className='BlogItem__image'>
          <svg className='BlogItem__image__svg' viewBox='0 0 264 138' />

          {get(data, 'image_1.url') && (
            <ImageLoader
              klass='BlogItem__image__img'
              src={get(data, 'image_1.url')}
              external
            />
          )}

          <span className='BlogItem__image__a'>
            {t('pages.index.know.blogs.a')}
          </span>
        </div>

        <div className='BlogItem__wrapper'>
          <dt className='BlogItem__dt'>{get(data, 'title')}</dt>

          <dd className='BlogItem__dd'>
            {moment(get(data, 'created_at')).format('MMM D, YYYY')}
          </dd>
        </div>
      </a>
    </Link>
  )
}

BlogItem.propTypes = {}

export default BlogItem
